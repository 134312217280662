<template>
  <section class="box-height qiyuFarDom" style="height: 100%">
    <resumeDetails
      :resumeDetailId="resumeId"
      :repeatResume="repeatResume"
      @getCallMobileInfo="getCallMobileInfo"
    ></resumeDetails>
  </section>
</template>
<script>
import {
  getResumeById,
  contactStatus,
  unlockResume,
  getCommonRecordList, //获取备注
  addRemarksToResume, //保存标签
  getResumeOperateRecord, //获取操作日志
  replaceResumeInPage, //替换简历
} from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import resumeDetails from "../../components/resumeDetail";
import Cookies from "js-cookie";
import Bus from "@/libs/bus";
export default {
  components: {
    // addMarkReacode,
    // resumeReport,
    //resumeInfo,
    // progream,
    resumeDetails,
  },
  //   props: ['resumeDetailId'],
  data() {
    return {
      repeatResume: { type: "重复", valId: "" },
      qiyuFarDom: "",
      callMobileInfo: {},
      isLoading: false,
      menuList: JSON.parse(this.getStore("permiss")),
      showLock: false,
      markName: "",
      actionUrl: ApiBaseUrl + "/api/file/uploadOne",
      myHeaders: {
        Authorization: Cookies.get("accessToken"),
      },
      upResumeData: {
        files: [],
        originalId: this.$route.query.id,
      },
      fileInfoList: [],
      fileList: [],
      farResumeList: "",
      stadusList: [{ name: "联系中" }, { name: "无状态" }], //收藏夹列表
      Authorization: Cookies.get("accessToken"),
      useId: parseInt(this.getStore("uid")),
      baseUrl: ApiBaseUrl,
      dialogVisibleEditStaus: false,
      dialogVisibleReplace: false,
      stadusVal: false,
      isEdit: true,
      InfoLoading: true,
      statusData: {
        status: "",
      },
      resumeId: "", //人选id
      resumeIds: [],
      formInline: {},

      ColumnInList: [], //行业
      props1: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },

      formInlineBase: {}, //人选基本信息
      remarkList: [],
      projectsList: [],
      forwardVocationList: "",
      forwardLocationList: "",
      employmentsList: [],
      educationsList: [],
      selTagList: [], //详情显示的标签
      RecordList: [], //备注记录列表
      commentColumns: [], //备注记录列表
      OperateList: [], //操作日志列表
      titName: "",
      activeName: "1",
      activeNames: "1",
      activeNameRemark: "first",
      activeNamesContact: "1",
      activeNameDetail: "first",
      showType: "1",

      iseditCus: false,
      isUpdateResume: false,
      stadusContent: "",
      staudsTitle: "",
      dialogVisibleRemark: false,
      isaddMark: false,
      isaddTag: false,
      isaddPro: false,

      dialogVisible: false,
      saveloading: false,
      createTag: {
        resumeId: "",
        remarkIds: [],
      },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      isPackUp: true,
    };
  },
  watch: {
    $route() {
      this.resumeId = this.$route.query.id;
      this.loadResumeDetils(this.$route.query.id);
    },
    "$route.query.diaTrue": function (value, oldValue) {
      if (value) {
        this.titName = "编辑人选信息";
        this.dialogVisible = true;
        this.iseditCus = true;
      }
    },
  },
  methods: {
    getCallMobileInfo(params) {
      Bus.$emit("qiyu", params);
    },
    handleChange1(val) {
      if (val.length > 0) {
        this.isPackUp = true;
      } else {
        this.isPackUp = false;
      }
    },
    addOrEditResume() {
      return new Promise((resolve) => {
        contactStatus({
          contactStatus: this.stadusVal,
          id: this.$route.query.id,
        }).then((res) => {
          resolve();
        });
      });
    },
    changeS(val) {
      if (val == "无状态") {
        this.stadusVal = null;
      } else {
        this.stadusVal = val;
      }
    },
    feedbackStadus() {
      this.statusData.status = "";
      this.dialogVisibleEditStaus = true;
    },
    savestadus() {
      this.dialogVisibleEditStaus = false;
      this.addOrEditResume().then(() => {
        this.loadResumeDetils(this.$route.query.id);
      });
    },
    cancel() {
      this.fileInfoList = [];
      this.iseditCus = false;
      this.isUpdateResume = false;
      this.isaddMark = false;
      this.isaddTag = false;
      this.isaddPro = false;
      this.removeStore("projects");
      this.removeStore("educations");
      this.removeStore("employments");
    },
    resetForm(formName) {
      this.fileInfoList = [];
      this.$refs[formName].resetFields();
    },
    handleClick(tab, event) {
      this.showType = tab.name;
      switch (tab.name) {
        case "1":
          this.loadResumeDetils(this.$route.query.id);
          break;
        case "2":
          this.loadRecodeList(this.resumeId, "人选");
          break;
        case "6":
          this.loadOperateList();
          break;
      }
    },
    handleClick2(tab, event) {},
    handleClick1(tab, event) {},
    unLock(rid) {
      //解锁
      this.$confirm(
        "<p style='font-size:18px'><strong>是否解除项目保护锁定？</strong></p><span>解锁后无法再次锁定候选人</span>",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "altCls",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          unlockResume({ resumeId: rid }).then((res) => {
            if (res.result) {
              this.$message.success("解锁成功");
              this.loadResumeDetils(rid);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    loadOperateList() {
      let secureContacts = null;
      if (this.formInlineBase.offerOrEntryProjectResumeId) {
        secureContacts = true;
      } else {
        secureContacts = false;
      }
      //加载操作日志
      getResumeOperateRecord({
        resumeId: this.resumeId,
        secureContacts: secureContacts,
      }).then((res) => {
        if (res.success) {
          this.OperateList = res.result;
        }
      });
    },
    editCus() {
      this.titName = "编辑人选信息";
      this.dialogVisible = true;
      this.iseditCus = true;
    },
    addMark() {
      this.titName = "添加备注";
      this.dialogVisibleRemark = true;
      this.isaddMark = true;
    },
    updateResume() {
      this.dialogVisibleReplace = true;
      this.isUpdateResume = true;
    },
    beforeUpload: function (file) {
      const isLt = file.size / 1024 / 1024 < 20;
      if (!isLt) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt;
    },
    handleInfoSuccess(res, file, fileList) {
      this.fileList = [];
      fileList.forEach((item) => {
        if (item.response) {
          this.fileList.push({
            fileName: item.name,
            filePath: item.response.result,
          });
        } else {
          this.fileList.push({
            fileName: item.name,
            filePath: item.filepath,
          });
        }
      });
    },
    handleInfoError(err) {
      this.$message.error("文件上传出错");
    },
    handleExceed(files, fileList) {
      this.$message.error("只能上传一个文件");
    },
    handleChange(file, fileList) {
      this.upResumeData.files = [];
      fileList.forEach((i) => {
        this.upResumeData.files.push(i.raw);
      });
    },
    saveChangedInfos() {
      this.saveloading = true;
      if (this.upResumeData.files.length == 0) {
        this.$message.error("请先上传人选文件");
        return false;
      }
      let body = new FormData();
      this.upResumeData.files.forEach((i) => {
        body.append("file", i);
      });
      body.append("originalId", this.upResumeData.originalId);
      replaceResumeInPage(body).then((res) => {
        if (res.success) {
          this.saveloading = false;
          this.dialogVisibleReplace = false;
          this.farResumeList = res.result;
          this.isUpdateResume = true;
          this.titName = "更新简历";
          this.dialogVisible = true;
          this.fileInfoList = [];
        }
      });
    },
    handleDiaClose() {
      this.fileInfoList = [];
      this.dialogVisibleReplace = false;
    },
    saveInfos() {
      //保存客户信息 调用子组件方法
      if (this.iseditCus) {
        this.$refs.saveCusInfo.saveResume();
      }
      if (this.isUpdateResume) {
        this.$refs.saveCusInfo.saveResume();
      }
      if (this.isaddMark) {
        //备注记录
        this.$refs.saveMark.saveMarkRecode();
      }
      if (this.isaddTag) {
        //打标签
        this.createTag.resumeId = this.resumeId;
        addRemarksToResume(this.createTag).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.dialogVisible = false;
            this.dialogVisibleRemark = false;
            this.cancel();
            this.loadResumeDetils(this.resumeId);
          }
        });
      }
    },
    linkToCom(e) {
      if (e) {
        this.$router.push({
          path: "/customer/details",
          query: {
            id: e,
          },
        });
      }
    },
    linkToProject(e) {
      if (e) {
        this.$router.push({
          path: "/progreamDetail",
          query: {
            id: e,
          },
        });
      }
    },
    getSon(data) {
      this.activeName = data;
      this.showType = data;
    },
    loadResumeDetils(id) {
      //获取人选详情
      this.cancel();
      this.activeName = "1";
      this.showType = "1";
      this.dialogVisible = false;
      this.formInline = "";
      getResumeById({
        resumeId: id,
        fromPage: "路由跳转人选详情",
      }).then((res) => {
        if (res.success) {
          if (res.result) {
            this.InfoLoading = false;
            this.formInlineBase = res.result.resumeInfo;
            this.educationsList = res.result.educationsList;
            this.employmentsList = res.result.employmentsList;
            this.projectsList = res.result.projectsList;
            this.remarkList = res.result.profileLabels;
            this.stadusContent = res.result.resumeInfo.contactStatus;
            this.staudsTitle = res.result.resumeInfo.contactRemark;
            if (res.result.forwardVocationList.length > 0) {
              let VocationName = [];
              res.result.forwardVocationList.forEach((item) => {
                if (item.forwardVocationName) {
                  VocationName.push(item.forwardVocationName);
                }
              });
              this.forwardVocationList = VocationName.join(" / ");
            }
            if (
              this.formInlineBase.certificate &&
              this.formInlineBase.certificate.indexOf("\n") != "-1"
            ) {
              //证书
              this.formInlineBase.certificate =
                this.formInlineBase.certificate.replace(/\n/g, "<br/>");
            }
            if (
              this.formInlineBase.training &&
              this.formInlineBase.training.indexOf("\n") != "-1"
            ) {
              //培训经历
              this.formInlineBase.training =
                this.formInlineBase.training.replace(/\n/g, "<br/>");
            }
            if (
              this.formInlineBase.personal &&
              this.formInlineBase.personal.indexOf("\n") != "-1"
            ) {
              //自我评价
              this.formInlineBase.personal =
                this.formInlineBase.personal.replace(/\n/g, "<br/>");
            }
            if (
              this.formInlineBase.others &&
              this.formInlineBase.others.indexOf("\n") != "-1"
            ) {
              //其他
              this.formInlineBase.others = this.formInlineBase.others.replace(
                /\n/g,
                "<br/>"
              );
            }
            if (res.result.forwardLocationList.length > 0) {
              let LocationName = [];
              res.result.forwardLocationList.forEach((item) => {
                if (item.forwardLocationName) {
                  LocationName.push(item.forwardLocationName);
                }
              });
              this.forwardLocationList = LocationName.join(" / ");
            }
          } else {
            this.$message.error("该人选不存在");
            let that = this;
            setTimeout(() => {
              that.$router.go(-1);
            }, 1000);
          }
        }
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadComList(this.resumeId);
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadComList(this.resumeId);
    },
    loadRecodeList(id, type) {
      //加载备注记录List
      this.cancel();
      this.dialogVisible = false;
      this.dialogVisibleRemark = false;
      getCommonRecordList({
        relativeId: id,
        type: type,
      }).then((res) => {
        if (res.success) {
          // 首先，先复制整个接口返回的数组
          this.RecordList = res.result.map((result) => ({
            ...result,
            // 以上通过扩展运算符复制整个obj

            // 修改commentColumns
            // filter是Array下的方法对吧
            // 要是坑爹后台返回的格式不对怎么办呢
            // 我们可以通过加默认值解决!!!
            commentColumns: (result.commentColumns || []).filter(
              (comment) => comment.value
            ),
          })); // 没问题吧？嗯
        }
      });
    },
    // 判断用户是否有解锁人选保护权限
    checkMenuList() {
      if (
        this.menuList.findIndex((item) => {
          return item.authority == "resume:resumeList:lock";
        }) == -1
      ) {
        this.showLock = false;
      } else {
        this.showLock = true;
      }
    },
  },
  mounted() {
    this.qiyuFarDom = document.querySelector(".qiyuFarDom");
  },
  created() {
    this.resumeId = this.$route.query.id;

    this.$dialog({
      options: {
        width: "90%",
        title: "人才",
        icon: "icon-rc_bt",
      },
      template: resumeDetails,
      props: {
        hover: { matchAll: true, majorSearch: false, hover: true },
        resumeDetailId: this.resumeId,
        repeatResume: { type: "列表", valId: "" },
      },
      emit: {
      },
    });
  },
};
</script>
<style lang="scss">
.LinkColor {
  color: #2b80ff;
  cursor: pointer;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}
.btnList {
  font-size: 12px;
  color: #666;
  height: 160px;
  padding-bottom: 15px;
  margin-top: 20px;
  .block {
    width: 33%;
    float: left;
    margin: 0 0 20px;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #ff3f3f;
    }
    i {
      font-size: 20px;
    }
    p {
      margin: 0;
    }
  }
}
.menuList {
  padding-inline-start: 0;
  border-bottom: 1px solid #ededed;
  .el-tabs {
    padding: 0 20px;
    .el-tabs__header {
      margin: 0;
    }
  }
}
.titleTag {
  display: block;
  background-color: #f6f6f6;
  height: 40px;
  line-height: 40px;
  span {
    height: 30px;
    border-left: 3px solid #ff3f3f;
    padding-left: 15px;
  }
}

.markRecode {
  height: calc(100% - 60px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    padding-inline-start: 0;
  }
  .el-timeline-item__timestamp {
    color: #bbb;
    font-size: 12px;
  }
  h4 {
    margin: 0;
    line-height: 32px;
  }
  .cardDetail {
    .edit {
      display: none;
    }
    &:hover {
      background: #fafafa;
      box-shadow: none;
      .edit {
        display: block;
      }
    }
  }
  .el-card__body {
    padding: 0 15px 5px 15px;
    ul {
      li {
        p {
          margin: 10px 0 0;
          color: #333;
        }
        .tit {
          font-size: 13px;
          color: #909399;
        }
        .con {
          color: #333;
        }
      }
    }
  }
  .edit {
    float: right;
    font-weight: normal;
    color: #ff3f3f;
    cursor: pointer;
  }
}

.tagLis {
  .el-checkbox-button {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
  }
  .el-checkbox-button:first-child .el-checkbox-button__inner {
    border-radius: 4px;
  }
  .el-checkbox-button:last-child .el-checkbox-button__inner {
    border-radius: 4px;
  }
}
.main_l {
  background-color: #fff;
  border-right: 20px solid #f2f2f2;
  height: 100%;
  padding: 0;
  position: relative;
  .el-form-item {
    margin-bottom: 0;
  }
  .demo-form-inline {
    padding: 0 20px;
    overflow-y: auto;
    height: calc(100% - 45px);
    .dash_line {
      height: 1px;
      background-color: #ededed;
      margin: 10px 0;
    }
    .protectCss {
      border: 1px solid #ffdddc;
      padding: 0 20px;
      background-color: #fff5f4;
      margin-bottom: 0;
    }
    .infoJian {
      .el-form-item {
        margin-bottom: 0;
      }
    }
    .el-form-item__label {
      height: 30px;
      line-height: 30px;
    }
    .el-form-item__content {
      line-height: 30px;
      color: #666;
    }
  }
  .el-tag + .el-tag {
    margin-left: 20px;
  }
  .resumeBase {
    .el-image {
      width: 100px;
      height: 100px;
      float: left;
      border: 1px solid #ddd;
      text-align: center;
      line-height: 100px;
      margin-right: 20px;
    }
    .resumeBase_r {
      p {
        font-size: 14px;
      }
      .resumeBase_r_base {
        font-weight: bold;
        position: relative;
        font-size: 18px;
        margin-bottom: 5px;
        s {
          text-decoration: none;
          font-weight: normal;
          margin-left: 10px;
          color: #666;
          font-size: 14px;
          span {
            padding: 0 5px;
            color: #ededed;
          }
        }
      }
      .resumeBase_r_te {
        margin-top: 20px;
        span {
          width: 33.3%;
          display: inline-block;
          margin-bottom: 10px;
          s {
            color: #666;
            text-decoration: none;
          }
          i {
            cursor: pointer;
            font-size: 13px;
            padding: 4px;
            border-radius: 50%;
            background-color: #f6f6f6;
            color: #ff3f3f;
            &:hover {
              color: #fff;
              background-color: #ff3f3f;
            }
          }
        }
      }
    }
  }
  .collapse {
    border: 0;
    .collapse_con {
      p {
        margin: 0;
        height: 30px;
        line-height: 30px;
        color: #666;
        font-size: 13px;
      }
    }
    .el-collapse-item__wrap {
      border: 0;
    }
    .el-collapse-item {
      border-bottom: 1px dashed #efefef;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .eduList {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    li {
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      border-bottom: 2px dashed #f6f6f6;
      p {
        margin: 0;
        float: left;
        height: 40px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
.uploadChanged {
  .el-upload.el-upload--text {
    width: 100% !important;
    .el-upload-dragger {
      margin: 0;
      width: 100% !important;
    }
  }
}
</style>
<style scope>
.searchIcon {
  font-size: 12px;
  position: relative;
  top: -5px;
}
.optionMore i {
  margin-right: 10px;
}
.iconHover:hover {
  cursor: pointer;
  color: #ff3f3f;
}
.iconfont1 {
  font-size: 22px;
  margin-right: 10px;
}
.resumeDetail {
  margin-bottom: 20px;
  overflow-y: auto;
  height: 90%;
}
.hoverLink {
  position: relative;
  top: -2px;
  color: #2979ff !important;
}
.delpaddleft {
  padding-left: 0 !important;
}
.fontColor {
  color: #999999;
}
.lightList li {
  color: #333333;
}
.lightBackground {
  min-height: 57px;
  padding: 5px; /* padding 决定边框宽度 */
  background-image: repeating-linear-gradient(
    135deg,
    #3858e0,
    #3858e0 40px,
    #da3737 40px,
    #da3737 80px
  );
}
.addRemark {
  width: 50%;
  float: right;
  text-align: right;
  box-sizing: border-box;
  padding-right: 15px;
  margin-top: 20px;
  cursor: pointer;
  color: #999999;
}
.addRemark:hover {
  color: #ff3f3f;
}
.btnList1 {
  height: calc(100% - 165px);
  border-top: 15px solid #f2f2f2;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 60px;
}
.contactStyle {
  padding: 5px;
  position: absolute;
  bottom: 15px;
  background: #f7faff;
  border: 1px solid #cee2ff;
  text-align: center;
  width: 60px;
  display: inline-block;
}
</style>
<style>
.personFoo1 .el-collapse-item__wrap,
.lightBackground .el-collapse-item__wrap {
  border: none !important;
}
.gradient-content {
  background-color: #fff;
  height: 100%;
  padding: 8px 15px 10px 15px;
  box-sizing: border-box;
}
</style>
